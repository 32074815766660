<template>
  <v-form ref="form" lazy-validation>
    <v-layout column>
      <v-layout align-center class="mx-4 mb-4">
        <DefaultSubtitle color="primary">Dane do faktury</DefaultSubtitle>
      </v-layout>
      <LabelTextField label="NIP" :value="invoiceDetails.nip" disabled>
      </LabelTextField>
      <LabelTextField
        label="IMIĘ I NAZWISKO LUB NAZWA FIRMY"
        :value="invoiceDetails.name"
        disabled
      >
      </LabelTextField>
      <LabelTextField label="ULICA" :value="invoiceDetails.street" disabled>
      </LabelTextField>
      <LabelTextField label="MIASTO" :value="invoiceDetails.city" disabled>
      </LabelTextField>
      <LabelTextField
        label="KOD POCZTOWY"
        :value="invoiceDetails.postcode"
        disabled
      >
      </LabelTextField>
      <LabelTextField
        label="ADRES E-MAIL"
        :value="invoiceDetails.email"
        disabled
      >
      </LabelTextField>
    </v-layout>
  </v-form>
</template>

<script>
import ProxyCRUDMixin from "@/mixins/ProxyCRUDMixin";
import SubscriptionsService from "@/services/subscriptions.service";

export default {
  mixins: [ProxyCRUDMixin],
  data() {
    return {
      invoiceDetails: {
        nip: "",
        name: "",
        street: "",
        city: "",
        postcode: "",
        email: "",
      },
    };
  },
  components: {
    LabelTextField: () => import("@/components/LabelTextField"),
    DefaultSubtitle: () => import("@/components/text/DefaultSubtitle"),
    DefaultLabel: () => import("@/components/text/DefaultLabel"),
    InfoButton: () => import("@/components/buttons/InfoButton"),
  },
  async created() {
    this.beforeRequest();
    SubscriptionsService.getInvoiceDetails()
      .then((reply) => {
        console.log("REPLY", reply);
        this.invoiceDetails = reply;
        this.reset();
      })
      .catch((e) => {
        this.handleError("Coś poszło nie tak");
      });
  },
};
</script>
